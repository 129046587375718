import styled from "styled-components"

const Divider = styled.div`
  width: 70px;
  height: 3px;
  margin-top: 8px;
  margin-bottom: 24px;
  background-color: #ed655a;
  justify-self: ${props => props.center ? "center" : ""};
  ${props => {
    if (props.full) {
      return `
        width: 100%;
        height: 1px;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: #e0dee9;
    `
    } else if (props.event) {
      return `
        width: 100vw;
        height: 1px;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: #e0dee9;
    `
    } else if (props.eventSmall) {
      return `
        height: 1px;
        width: 108%;
        margin-left: -8%;
        background-color: #e0dee9;
        @media screen and (max-width: 800px) {
          margin-left: 0%;
          width: 100%;
        }
      `
    } else if (props.fullOrange) {
      return `
        width: 150px;
        height: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: #ed655a;
        @media screen and (max-width: 800px) {
          width: 100px;
        }
    `
    }
  }}
`

export default Divider